var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"id":_vm.message.time}},[_c('div',{staticClass:"parent--container my--container qcw-group",class:_vm.message.date ? 'contain-date' : ''},[_c('div',{staticClass:"qcw-comment-container qcw-comment--text comment--me"},[(_vm.message.date)?_c('div',{staticClass:"qcw-comment-date"},[_c('div',[_vm._v(_vm._s(_vm.message.date))])]):_vm._e(),_c('div',{staticClass:"qcw-comment comment--me comment--parent comment--last"},[_c('div',{staticClass:"qcw-avatar"},[_c('img',{attrs:{"src":_vm.agentImage,"alt":"rw"}})]),_c('div',{staticClass:"qcw-comment__message",class:_vm.message.status === 'sent' && _vm.message.deleted !== '1'
              ? 'hover-effect'
              : ''},[_c('div',{staticClass:"tip",staticStyle:{"border-left-color":"rgb(255, 255, 255)","border-right-color":"transparent"}}),_c('span',{staticClass:"qcw-comment__username qcw-comment__username--hide"},[_vm._v("Personal")]),_c('div',{staticClass:"qcw-comment__more flex items-center",on:{"click":function($event){return _vm.openMsgOption()}}},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v("more_horiz")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.message.uuid === _vm.uuidCurrentOpen)?_c('div',{staticClass:"qcw-comment__more--menu more-kanan"},[_c('ul',[(_vm.message.status === 'sent' && _vm.message.deleted !== '1')?_c('li',{staticClass:"icon-trash",on:{"click":function($event){return _vm.$emit('removeMessage', _vm.message)}}},[_vm._v(" Delete ")]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"comment-text",staticStyle:{"z-index":"10"}},[_c('div',[(
                  ['text', 'agent-autoreply', 'action'].includes(_vm.message.tipe)
                )?_c('span',{staticClass:"qcw-comment__content",domProps:{"innerHTML":_vm._s(
                  _vm.message.tipe === 'action'
                    ? `<b>FORM</b> : ${_vm.message.message}`
                    : _vm.message.message
                )}}):_vm._e()]),(_vm.message.tipe === 'image')?_c('div',{staticClass:"qcw-comment__content"},[_c('a',{attrs:{"href":_vm.mixGetImage(_vm.message.message),"target":"_blank"}},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":_vm.mixGetImage(_vm.message.message),"alt":_vm.message.message}})])]):_vm._e(),(_vm.message.tipe === 'file')?_c('div',{staticClass:"qcw-comment__content"},[_c('svg',{staticClass:"qc-icon mt-0"},[_c('use',{attrs:{"xlink:href":"#rw-file-download"}})]),_c('a',{attrs:{"href":_vm.mixGetFiles(_vm.message.message),"target":"_blank"}},[_vm._v("Download")])]):_vm._e()]),(_vm.message.status === 'sent')?_c('span',{staticClass:"qcw-comment__time"},[_vm._v(" "+_vm._s(_vm.mixEpochToHours(_vm.message.time))+" ")]):_vm._e(),_c('div',[_c('div',{staticClass:"qcw-comment__state qcw-comment__state--read"},[(_vm.message.status === 'sent')?_c('svg',{staticClass:"qc-icon"},[_c('use',{attrs:{"xlink:href":"#rw-check"}})]):_vm._e(),(_vm.message.status === 'pending')?_c('svg',{staticClass:"rw-spin qc-icon",staticStyle:{"width":"12px","height":"25px"}},[_c('use',{attrs:{"xlink:href":"#rw-spin"}})]):_vm._e(),(_vm.message.status === 'failed')?_c('svg',{staticClass:"qc-icon retry",on:{"click":function($event){return _vm.retry()}}},[_c('use',{attrs:{"xlink:href":"#rw-retry"}})]):_vm._e()])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }