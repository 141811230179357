<template>
  <div
    class="all-container"
    v-shortkey.once="shortcut"
    @shortkey="listenShortcut"
  >
    <div class="app-container">
      <div class="app-content">
        <icon-svg />

        <conversationList @fromChildDialogSelected="handleEmitFromChild" />

        <chatArea
          :writeMessage.sync="forChildProps.writeMessage"
          @toggleChatInfo="onChildToggleChatInfo"
          @search="handleEmitFromChild"
        />

        <userInfo
          @toggleChatInfo="onChildToggleChatInfo"
          @sendCanned="handleEmitFromChild"
        />
      </div>
    </div>
  </div>
</template>

<script>
import conversationList from "@/components/staff/AthreadList.vue";
import chatArea from "@/components/staff/BchatArea.vue";
import userInfo from "@/components/staff/CuserInfo.vue";

import $ from "jquery";
import { mapGetters } from "vuex";
import { staffCookies } from "@/common/mixins/mix_cookies";
import { snooze, lsConfig, lsUnread } from "@/common/mixins/mix_localStorage";
import { db, Auth } from "@/common/firebase/init";
import {
  alert,
  waktu,
  request,
  rwSound,
  util,
} from "@/common/mixins/mix_helper";
import AppConfig from "@/common/config/app.config.json";
import "bootstrap-notify";
import IconSvg from "@/components/widget/IconSvg";

export default {
  mixins: [
    waktu,
    alert,
    staffCookies,
    request,
    snooze,
    lsConfig,
    rwSound,
    lsUnread,
    util,
  ],
  components: {
    IconSvg,
    conversationList,
    chatArea,
    userInfo,
  },
  data() {
    return {
      forChildProps: {
        writeMessage: "",
      },
      masterMessages: [], // for filter
      chatInfo: false, // toggle user info
      shortcut: {
        zero: ["ctrl", "0"],
        satu: ["ctrl", "1"],
        dua: ["ctrl", "2"],
        tiga: ["ctrl", "3"],
        empat: ["ctrl", "4"],
        lima: ["ctrl", "5"],
        enam: ["ctrl", "6"],
        tujuh: ["ctrl", "7"],
        delapan: ["ctrl", "8"],
        nine: ["ctrl", "9"],
      },
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * init created
     * --------------------------------------------------------------
     */
    initCreated: function () {
      let epochNow = this.getAllVuexAgent._agentLastMessage.time;
      this.$store
        .dispatch("FbAuthStateChange")
        .then((user) => {
          /**
           * --------------------------------------------------------------
           * listen new threads
           * --------------------------------------------------------------
           */
          db.ref(`agents-threads/${user.uid}`)
            .orderByChild("last_time")
            .startAt(epochNow)
            .on("child_added", (items) => {
              let roti = this.cookies.get("staff");
              if (
                typeof roti !== "undefined" &&
                typeof roti.id !== "undefined"
              ) {
                let firebase = {
                  key: items.key,
                  field: items.val(),
                };
                this.threadNew(firebase, roti);
              } else {
                this.mixSwalBlock(
                  "Sorry cookies not found. new0001 <br> <small>you can't continue this session</small>"
                );
              }
            });

          /**
           * --------------------------------------------------------------
           * listen perubahan thread firebase
           * --------------------------------------------------------------
           */
          db.ref(`agents-threads/${user.uid}`).on(
            "child_changed",
            (response) => {
              let roti = this.cookies.get("staff");
              if (
                typeof roti !== "undefined" &&
                typeof roti.id !== "undefined"
              ) {
                let firebase = {
                  key: response.key,
                  field: response.val(),
                };
                this.threadChanged(firebase, roti);

                /*const { id, verified, email_verified } = response.val();
              const threadIndex = this.getThreads.all.findIndex((e) => e.id === id);
              if (threadIndex > -1) {
                this.$store.dispatch("DialogUpdate", {
                  index: threadIndex,
                  threadid: id,
                  record: {
                    is_takeover: true,//is_takeover,
                    verified: verified,
                    email_verified: email_verified
                  },
                });

                // UPDATE IS_TAKEOVER FOR CURRENT THREAD OPEN
                const threadOpened = this.getDialogActive;
                if (typeof threadOpened.id !== "undefined" && threadOpened.id === id) {
                  this.$store.dispatch("DialogOpenUpdate", {
                    is_takeover: true,//is_takeover,
                  });
                }
              }else{
                this.$store.dispatch("DialogAppend", {
                  firebaseid: response.key,
                  thread: response.val(),
                });
              }*/
              } else {
                this.mixSwalBlock(
                  "Sorry, cookies not found.<br> <small>you can't continue this session</small>"
                );
              }
            }
          );

          /**
           * --------------------------------------------------------------
           * listen new message yang lebih dari epochNow (biar tidak append & muncul notif terus)
           * --------------------------------------------------------------

          db.ref("messages-agent")
            .orderByChild("time")
            .startAt(epochNow)
            .on("child_added", (response) => {
              let messages = response.val();

              this.incomingMessage(messages);
            });
           */

          /**
           * --------------------------------------------------------------
           * check connection firebase
           * base on https://firebase.google.com/docs/database/web/offline-capabilities#web-version-8_3
           * counterConnection : karena default request 1st time is false
           * --------------------------------------------------------------
           */
          /*let counterConnection = 1;
          db.ref(".info/connected").on("value", (snap) => {
            if (snap.val() === true) {
              this.$store.dispatch("connected", { firebase: true });
            } else {
              if (counterConnection > 1) {
                this.$store.dispatch("connected", { firebase: false });
              } else {
                counterConnection++;
              }
            }
          });*/

          /**
           * --------------------------------------------------------------
           * crontab every 1 minute
           * --------------------------------------------------------------
           */
          this.$crontab.addJob({
            name: "counter",
            interval: {
              seconds: "30",
            },
            job: this.handleCronTab,
          });

          /**
           * --------------------------------------------------------------
           * listen cron agent/uid current ex. set away, kick from panel admin spv
           * --------------------------------------------------------------
           */
          let cronAgent = db.ref(`cron-agent/${user.uid}`);
          cronAgent
            .orderByChild("time")
            .startAt(epochNow)
            .on("child_added", (cron) => {
              let item = cron.val();
              if (typeof item.tipe !== "undefined") {
                /**
                 * --------------------------------------------------------------
                 * set status away by supervisor
                 * update cookies isonline : false, force reload page
                 * --------------------------------------------------------------
                 */
                if (item.tipe === "agent-set-away") {
                  this.cookies.add({ isOnline: false });
                  let msg =
                    typeof item.message !== "undefined"
                      ? item.message
                      : "Your account has been set <b>AWAY</b>.";
                  this.mixToast(msg, {
                    style: {
                      background:
                        "linear-gradient(to right, #fa709a 0%, #fee140 100%)",
                    },
                    duration: 10000,
                  });
                  this.$store.commit("toggleIsOnline", false);
                  // this.$swal({
                  //   title: "Warning",
                  //   html: msg,
                  //   icon: "warning",
                  //   confirmButtonText: "Reload",
                  // }).then(() => {
                  //   this.$router.go();
                  // });
                }

                /**
                 * --------------------------------------------------------------
                 * kick by supervisor in panel admin
                 * firebase sign-out, remove cookies
                 * enter, escape, outside false : make sure agent membaca dan mengetahui
                 * telah dikick dari panel (asumsi agent current sdg buka livechat)
                 * jika agent current tidak buka livechat, ketika buka lagi auto redirect /auth. kok bisa ?
                 * ntah padahal gk listen :(
                 * --------------------------------------------------------------
                 */
                if (item.tipe === "agent-set-offline") {
                  this.$swal({
                    title: "Kicked",
                    html: "Your account has been <b>KICKED</b> by supervisor.<br>Please re-login again.",
                    icon: "error",
                    confirmButtonText: "Re-login",
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    allowEscapeKey: false,
                  }).then(() => {
                    this.$store.dispatch("FbAuthSignOut");
                    this.cookies.remove();
                    this.$router.push({ path: "/auth" });
                  });
                }
              }

              cronAgent.remove(); // --- make db stay null cause use child_added
            });

          /**
           * --------------------------------------------------------------
           * listen perubahan status agent, broadcast to all agent
           * --------------------------------------------------------------
           */
          db.ref("cron-agent/history-status-agent")
            .orderByChild("time")
            .startAt(epochNow)
            .on("child_added", (response) => {
              let history = response.val();
              if (!this.cookies.sameWithAgentCurrent(history.agentid)) {
                this.mixAlert("Bot", history.message, "st");
              }
            });

          /**
           * --------------------------------------------------
           * listen remove message dari another agent
           * utk current agent menggunakan response api delete
           * tidak menggunakan listen firebase
           * --------------------------------------------------
           */
          db.ref(`cron-agent/remove-message/${user.uid}`)
            .orderByChild("timeCron")
            .startAt(epochNow)
            .on("child_added", (response) => {
              let params = response.val();
              this.getChat.filter((all, index) => {
                if (all.uuid === params.uuid) {
                  this.$store.dispatch("staffMessageUpdate", {
                    index: index,
                    record: {
                      message: params.messageDelete,
                    },
                  });
                }
              });

              // update thread message
              const threads = this.getThreads.all;
              threads.filter((thread, index) => {
                if (thread.id === params.threadid) {
                  if (thread.message === params.message) {
                    this.$store.dispatch("DialogUpdate", {
                      index: index,
                      threadid: thread.id,
                      record: {
                        message: "<i>pesan dihapus</i>",
                      },
                    });
                  }
                }
              });
            });

          /**
           * --------------------------------------------------
           * listen agents-messages order by more than current time
           * --------------------------------------------------
           */
          db.ref(`agents-messages/${user.uid}`)
            .orderByChild("time")
            .startAt(epochNow)
            .on("child_added", (rs) => {
              let message = rs.val();
              if (message.tipe === "action") {
                this.incomingMessage(message, true);
              } else {
                this.incomingMessage(message);
              }
            });
        }) // --- end then firebase auth state chage ---

        /**
         * --------------------------------------------------------------
         * jika token expired maka force logout with remove cookies
         * --------------------------------------------------------------
         */
        .catch(() => {
          console.error(
            "You have been kicked from the panel, because the token has expired."
          );
          this.cookies.remove();
          this.$router.push("/auth");
          // --- just for report monitoring ---
          let token = localStorage.getItem(AppConfig.rwStorage.agent.token);
          this.$store.dispatch("sendLogAPI", {
            actor: "agent",
            message: "Firebase auth state change, token expired",
            token: token,
          });
        }); // --- end catch firebase auth state chage ---

      /**
       * --------------------------------------------------------------
       * tidak didalam statechange, karna ini global
       * get server time via firebase
       * --------------------------------------------------------------
       */
      db.ref("others/server-time").on("value", (response) => {
        if (response.val()) {
          let server = response.val();
          if (server) {
            this.$store.dispatch("ServerTime", { time: server.time });
          }
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * dapatkan token baru untuk login ulang
     * --------------------------------------------------------------
     */
    getReloginToken: function () {
      this.API.get(this.URL.agent.token)
        .then(({ data }) => {
          console.warn("data", data);
          this.$store
            .dispatch("FbTokenLogin", data.token)
            .then(() => {
              this.initCreated();
            })
            .catch(() => {
              this.$swal(
                "Error",
                "Sesi anda sudah habis, copy pesan terakhir Anda, lalu refresh halaman ini",
                "error"
              );
              this.cookies.remove();

              // --- just for report monitoring ---
              this.$store.dispatch("sendLogAPI", {
                actor: "agent",
                message: "Failed to re-generate token firebase",
              });
            });
        })
        .catch((err) => {
          if (typeof err.response !== "undefined") {
            if (err.response.status === 401) {
              // force logout
              this.$swal(
                "Error",
                "Sesi anda sudah habis, copy pesan terakhir Anda, lalu refresh halaman ini",
                "error"
              );
              this.cookies.remove();

              // --- just for report monitoring ---
              this.$store.dispatch("sendLogAPI", {
                actor: "agent",
                message: "Failed to get /agent/token API karena status === 401",
              });
            } else {
              let message = err.response.data.message;
              this.mixSwalBlock(message);
              this.cookies.remove();

              // --- just for report monitoring ---
              this.$store.dispatch("sendLogAPI", {
                actor: "agent",
                message: "Failed to get /agent/token API karena status !== 401",
              });
            }
          } else {
            // --- just for report monitoring ---
            this.$store.dispatch("sendLogAPI", {
              actor: "agent",
              message:
                "Failed to get /agent/token API karena err.response === undefined",
            });

            this.mixSwalBlock(err.message);
            this.cookies.remove();
          }
        });
    },

    onChildToggleChatInfo: function () {
      this.chatInfo = !this.chatInfo;
    },

    /**
     * --------------------------------------------------------------
     * for handle emit from child
     * @params {type:String, data: anything }
     * --------------------------------------------------------------
     */
    handleEmitFromChild: function (params) {
      let message = this.forChildProps.writeMessage;
      switch (params.type) {
        case "sendCanned":
          this.forChildProps.writeMessage = message + " " + params.data.message;
          break;

        case "selectThread":
          this.forChildProps.writeMessage = "";
          break;
      }
    },

    /**
     * --------------------------------------------------------------
     * when refresh page, this script will be get thread open in this agent
     * --------------------------------------------------------------
     */
    xhrThreads: function () {
      let staff = this.cookies.get("staff");
      if (typeof staff !== "undefined" && typeof staff.id !== "undefined") {
        this.API.post(this.URL.agent.threads, { staffid: staff.id })
          .then(({ data }) => {
            if (data.data.length) {
              let appendThread = [];
              data.data.forEach((thread) => {
                let ago = this.mixGetAgo(thread.time);

                thread["now"] = this.mixEpoch(); // append index now  (untuk time thread)
                thread["alertAway"] =
                  ago >= this.storage.time_away && thread.reply;
                thread["unread"] = this.initSyncUnreadThread(thread.id);
                appendThread.push(thread);
              });
              this.$store.dispatch("DialogSet", appendThread);
            }
          })
          .catch((err) => {
            if (typeof err.response !== "undefined") {
              if (err.response.status === 401) {
                // force logout
                this.cookies.remove();
                location.href = "/auth";
              } else {
                let message = err.response.data.message;
                this.mixSwalBlock(message);
              }
            } else {
              this.mixSwalBlock(err.message);
            }
          });
      } else {
        this.mixSwalBlock("Sorry, cookies not found.");
      }
    },

    scrollToBottomChat: function () {
      let el = $(".qcw-comments.isReading");
      if (el.length) {
        el.animate(
          {
            scrollTop: el[0].scrollHeight,
          },
          1000
        );
      }
    },

    /**
     * --------------------------------------------------------------
     * handle if incoming new thread
     * dilakukan filter forward ke agent yang ada di thread firebase
     * --------------------------------------------------------------
     */
    threadNew: function (firebase, roti) {
      let item = firebase.field;
      if (typeof item.agents !== "undefined" && item.agents.includes(roti.id)) {
        let customer = {
          firebaseid: firebase.key,
          thread: item,
        };

        let threads = this.getThreads.all;
        if (threads.length === 0 || !this.threadIsExist(item.id)) {
          this.$store.dispatch("DialogAppend", customer);
        }
      }
    },

    /**
     * --------------------------------------------------------------
     * manipulate when thread has been changed
     * @param firebase : Object firebase {field:{},key:{}}
     * @param roti : Object cookies staff
     * @param updateThread : boolean apakah diproses check thread / tidak
     * --------------------------------------------------------------
     */
    threadChanged: function (firebase, roti, updateThread = false) {
      let item = firebase.field;
      if (typeof item.agents !== "undefined" && item.agents.includes(roti.id)) {
        let threads = this.getThreads.all;
        if (this.threadIsExist(item.id)) {
          const thread_index = threads.findIndex(
            (thread) => thread.id === item.id
          );
          if (updateThread && thread_index !== -1) {
            let record = {
              time: item.last_time,
              name: item.name,
              reply: item.is_reply,
              unread: !item.is_reply,
              message: item.last_message,
              alertAway: false,
            };

            this.$store.dispatch("DialogUpdate", {
              index: thread_index,
              threadid: item.id,
              record: record,
            });
          }
        } else {
          db.ref("threads/" + item.id)
            .once("value")
            .then((snap) => {
              let fbthread = snap.val();
              item.custname = fbthread.custname;
            })
            .finally(() => {
              this.$store.dispatch("DialogAppend", {
                firebaseid: firebase.key,
                thread: item,
              });
            });
        }
      }
    },

    /**
     * --------------------------------------------------------------
     * cek apakah agent ini memiliki id thread
     * --------------------------------------------------------------
     */
    threadIsExist: function (threadid) {
      let isExist = this.getThreads.all.filter(
        (thread) => thread.id === threadid
      );
      return isExist.length !== 0;
    },

    /**
     * --------------------------------------------------------------
     * handle pesan baru
     * @params item : respon row from firebase
     * @params pushMessage : jika true tidak append msg melainkan push ex. cron
     * --------------------------------------------------------------
     */
    incomingMessage: function (item, pushMessage = false) {
      let roti = this.cookies.get();
      let is_staff_exist =
        typeof roti !== "undefined" &&
        typeof roti.staff !== "undefined" &&
        typeof roti.staff.id !== "undefined";

      this.$store.dispatch("appendMessageStaffLog", item);
      if (is_staff_exist) {
        let agentHasInclude =
          typeof item.agents !== "undefined" &&
          item.agents.includes(roti.staff.id);
        if (agentHasInclude) {
          let actor = item.actor;

          console.warn(
            "Agent new message",
            item.time + ":" + item.message + ":" + item.uuid
          );

          /**
           * --------------------------------------------------------------
           * update vuex last message agent
           * --------------------------------------------------------------
           */
          this.$store.dispatch("agentLastMessage", item);

          /**
           * --------------------------------------------------------------
           * send desktop notif ke agent yg didalam chat tsb
           * --------------------------------------------------------------
           */
          if (actor === "system" && item.tipe === "agent-close") {
            this.mixAlert("Agent : " + item.name, item.message);
            this.handleThreadClose(item, roti);
          } else {
            let isThreadOpen =
              typeof roti.threadOpen !== "undefined" &&
              typeof roti.threadOpen.id !== "undefined" &&
              roti.threadOpen.id === item.threadid;

            /**
             * --------------------------------------------------------------
             * jika message dari client atau pesan dari agent tapi bukan current
             * yang selanjutnya diberi alert desktop notif
             * --------------------------------------------------------------
             */
            let castFB = parseInt(item.staffid);
            let castLocal = parseInt(roti.staff.id);
            if (
              actor !== "staff" ||
              (actor === "staff" && castFB !== castLocal)
            ) {
              let preFix = actor === "staff" ? "Agent : " : "";

              /**
               * --------------------------------------------------------------
               * pengecekan thread change, menyesuaikan data yang dibutuhkan
               * --------------------------------------------------------------
               */
              let threadChange = {
                key: item.threadid,
                field: {
                  id: item.threadid,
                  agents: item.agents,
                  last_time: this.mixEpoch(),
                  is_reply: false,
                  last_message: item.message,
                  subid: item.subthreadid,
                },
              };

              if (actor === "customer") {
                threadChange.field.name = item.name;
              }
              this.threadChanged(threadChange, roti.staff, true);

              /**
               * --------------------------------------------------------------
               * customise sound alert
               * callback : handle notif onclick lgsg get message via vuex
               * --------------------------------------------------------------
               */
              let callback = (opt) => {
                if (typeof opt.threadid !== "undefined") {
                  this.$store.dispatch("getMessage", {
                    get: true,
                    threadid: opt.threadid,
                  });
                }
              };
              let name = preFix + item.name;
              const status = this.getAllVuexAgent._isOnline;
              let soundCode =
                item.tipe === "agent-join" ? "tf" : status ? "in" : "sa";
              this.mixAlert(name, item.message, soundCode, callback, item);

              /**
               * --------------------------------------------------------------
               * jika message berasal dari thread yang sedang terbuka maka
               * langsung dilakukan append & remove 1stmesse thread tsb
               * --------------------------------------------------------------
               */
              if (isThreadOpen) {
                this.$store.dispatch("staffAppendNewMessage", item);
                this.$store.dispatch("removeFirstMessage");
              }
            }

            /**
             * --------------------------------------------------------------
             * handle thread yang sedang AKTIF / DIBUKA
             * --------------------------------------------------------------
             */
            if (isThreadOpen) {
              let index_message = this.getChat.findIndex(
                (e) => e.uuid === item.uuid
              );
              if (
                (actor === "staff" && castFB === castLocal && pushMessage) ||
                index_message === -1
              ) {
                this.$store.dispatch("staffAppendNewMessage", item);
                this.$store.dispatch("removeFirstMessage");
              } else {
                this.$store.dispatch("staffMessageUpdate", {
                  index: index_message,
                  record: item,
                });
              }

              setTimeout(() => {
                this.scrollToBottomChat();
              }, 500);

              /**
               * --------------------------------------------------------------
               * remove thread if agent has leaved the thread
               * --------------------------------------------------------------
               */
              if (item.tipe === "agent-leave") {
                this.$store.dispatch("removeActiveAgent", item.agents);
              }
            }

            /**
             * --------------------------------------------------------------
             * karena tidak bisa menyesuaikan perubahan DOM
             * --------------------------------------------------------------
             */
            setTimeout(() => {
              this.$store.dispatch("DialogMove", { move: true });
            }, 400);
          }
        }
      } else {
        this.mixSwalBlock(
          "Sorry cookies not found. <br> <small>you can't continue this session</small>"
        );
      }
    },

    /**
     * --------------------------------------------------------------
     * jika ada message tipe agent-close
     * cek jika message threadid == cokkies thread open maka close panel chat
     * --------------------------------------------------------------
     */
    handleThreadClose: function (message, cookies) {
      if (message.threadid === cookies.threadOpen.id) {
        this.$store.dispatch("toggleUserShow", { show: false });
        this.$store.dispatch("removeDialogActive");
      }
      this.$store.dispatch("DialogRemove", {
        threadid: message.threadid,
      });
    },

    /**
     * --------------------------------------------------------------
     * inisialisasi supaya localStorage === vuex ketika reload page
     * --------------------------------------------------------------
     */
    initSyncSnoozeJSandVuex() {
      let snoozeJS = this.mixGetSnooze();
      if (
        typeof snoozeJS !== "undefined" &&
        typeof snoozeJS.record !== "undefined" &&
        typeof snoozeJS.alert !== "undefined"
      ) {
        this.$store.dispatch("SnoozeSet", snoozeJS);
      }
    },

    /**
     * --------------------------------------------------------------
     * for sync unread thread when agent reload page
     * --------------------------------------------------------------
     */
    initSyncUnreadThread(threadid) {
      let unreadJS = this.mixUnreadGet();
      if (unreadJS !== null) {
        return unreadJS.includes(threadid);
      }
      return false;
    },

    /**
     * --------------------------------------------------------------
     * notif when snooze time over
     * --------------------------------------------------------------
     */
    notifySnoozeTimeOver(message = "", title = "Snooze Time Over : ") {
      $.notify(
        {
          title: `<b>${title}</b>`,
          message: message,
        },
        {
          type: "danger",
          delay: 8000,
          newest_on_top: true,
        }
      );
      //this.mixSound();
    },

    /**
     * --------------------------------------------------------------
     * for handle crontab
     * --------------------------------------------------------------
     */
    handleCronTab: function ({ exec_date }) {
      let epochNow = this.mixEpoch();
      console.warn("getStartCron:" + this.getStartCron);
      if (this.getStartCron < 1) {
        console.warn("1st cron skipped");
        this.$store.dispatch("startCron");
        return true;
      }

      console.warn(`Cron is running at : ${exec_date}`);

      this.$store.dispatch("DialogMove", { move: true });
      this.$store.dispatch("DialogUpTime", { now: epochNow });

      /**
       * --------------------------------------------------------------
       * check ada snooze nggak + remove from localStorage
       * --------------------------------------------------------------
       */
      let snooze = this.mixGetSnooze();
      if (snooze) {
        snooze.record.forEach((val) => {
          if (val.timeShow <= epochNow) {
            this.notifySnoozeTimeOver(
              `A reminder for the chat <b>${val.customerName}</b>, please check it`
            );
            this.removeSnooze(val.threadId); // remove from localStorage
            this.$store.dispatch("SnoozeRemove", val.threadId); // remove from vuex & add alert snooze
          }
        });
      }

      /**
       * --------------------------------------------------------------
       * check dialog away, push dekstop notif
       * --------------------------------------------------------------
       */
      this.getThreads.all.forEach((thread, index) => {
        let ago = this.mixGetAgo(thread.time);
        if (
          ago >= this.storage.time_away &&
          thread.reply &&
          !thread.alertAway
        ) {
          this.mixAlert(
            "Thread Away",
            `The thread ${thread.name} enters away mode.`
          );
          this.$store.dispatch("DialogUpdate", {
            index: index,
            record: { alertAway: true },
          });
        }
      });

      /**
       * --------------------------------------------------------------
       * request firebase message base on last time vuex
       * --------------------------------------------------------------
       */
      this.getFirebaseMessage();

      /**
       * --------------------------------------------------------------
       * cek apakah token user di firebase masih valid ?
       * --------------------------------------------------------------
       */
      this.getFirebaseAuth();

      /**
       * --------------------------------------------------------------
       * compare time local & server
       * --------------------------------------------------------------
       */
      this.compareTimeLocalServer();

      this.$store.dispatch("startCron");

      return true;
    },

    /**
     * --------------------------------------------------------------
     * request get firebase message agent, for make sure has been deliver to agent
     * --------------------------------------------------------------
     */
    getFirebaseMessage: function () {
      let lastTime = this.getAllVuexAgent._agentLastMessage.time - 60;

      console.warn(`Cron firebase last time : `, lastTime);
      db.ref("messages-agent")
        .orderByChild("time")
        .startAt(lastTime)
        .once("value", (response) => {
          let message = response.val();
          /**
           * --------------------------------------------------------------
           * looping dan check ke current thread ada tidak biar gk double
           * --------------------------------------------------------------
           */
          for (let i in message) {
            // if(message[i].actor == "customer"){
            if (typeof this.getChatLog[message[i].uuid] === "undefined") {
              /**
               * --------------------------------------------------------------
               * jika message belum ada di chat (array) maka lgsg push
               * dan send log to API
               * --------------------------------------------------------------
               */
              // if (message[i].tipe === "text") {
              //   this.$store.dispatch("sendLogAPI", {
              //     ket: "Agent pesan miss antara firebase & local",
              //     log: message[i],
              //   });
              // }

              this.incomingMessage(message[i], true);
            } else {
              /* pesan sudah ter append dg baik - no need action */
            }
            // }
          }
        });
    },
    /**
     * --------------------------------------------------------------
     * request get firebase message agent, for make sure it has been deliver to agent
     * --------------------------------------------------------------
     */
    getFirebaseAuth: function () {
      this.$store
        .dispatch("FbAuthStateChange")
        .then(() => {
          /**
           * --------------------------------------------------------------
           * jika token expired maka force logout with remove cookies
           * --------------------------------------------------------------
           */
        })
        .catch(() => {
          console.error(
            "You have been kicked from the panel, because the token has expired."
          );
          this.getReloginToken();
        }); // --- end catch firebase auth state chage ---
    },

    /**
     * --------------------------------------------------------------
     * refresh / re-generate token firebase authentication
     * https://firebase.google.com/docs/reference/js/v8/firebase.User#getidtoken
     * --------------------------------------------------------------
     */
    reGenerateTokenFirebaseAuth() {
      let counterCron = this.getStartCron;
      if (counterCron === 1 || counterCron % 20 === 0) {
        Auth.onAuthStateChanged((user) => {
          if (user) {
            user.getIdToken(true).then(() => {
              // user.getIdToken(true).then((token) => {
              // console.warn("reload agent token", token);
              user.reload();
            });
          }
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * compare time server with local
     * --------------------------------------------------------------
     */
    compareTimeLocalServer() {
      let vuex = this.getVuexGeneral._server_time;
      let timeServer = vuex.time;
      let timeLocal = this.mixEpoch();
      let diff = Math.abs(timeServer - timeLocal);
      let getDiffTime = AppConfig.diffAlertDCinSecond;
      if (typeof getDiffTime !== "undefined" && diff > getDiffTime) {
        this.$store.dispatch("connected", { firebase: false });
      } else {
        this.$store.dispatch("connected", { firebase: true });
      }
    },

    /**
     * --------------------------------------------------------------
     * untuk trigger gesture sound supaya bunyi
     * kenapa sessionStorage : supaya terhapus setelah tutup browser
     * --------------------------------------------------------------
     */
    showAlertGestureSound() {
      if (typeof Storage !== "undefined") {
        let name = "gestureSound";
        let flag = sessionStorage.getItem(name);
        if (flag === null) {
          sessionStorage.setItem(name, "init");
        } else {
          this.$swal({
            title: "Warning",
            html: "Anda telah melakukan reload halaman.<br>Klik tombol dibawah ini untuk melanjutkan",
            icon: "warning",
            confirmButtonText: "Lanjutkan",
          });

          let get = sessionStorage.getItem(name);
          if (get === "init") {
            sessionStorage.setItem(name, 1);
          } else {
            sessionStorage.setItem(name, parseInt(get) + 1);
          }
        }
      } else {
        this.$swal(
          "Warning",
          "Sorry, your browser does not support Web Storage",
          "error"
        );
      }
    },

    listenShortcut(e) {
      if (!this.getThreads.all.length) {
        return;
      }

      if (typeof this.getAllVuexAgent._dialogActive.id === "undefined") {
        return;
      }

      let master = this.getAllVuexAgent._shortcutCanned;
      let check = (e) => e in master;
      let text = (i) => master[i].body;

      switch (e.srcKey) {
        case "zero":
          if (check("ctrl+0")) {
            this.forChildProps.writeMessage = text("ctrl+0");
          }
          break;
        case "satu":
          if (check("ctrl+1")) {
            this.forChildProps.writeMessage = text("ctrl+1");
          }
          break;
        case "dua":
          if (check("ctrl+2")) {
            this.forChildProps.writeMessage = text("ctrl+2");
          }
          break;
        case "tiga":
          if (check("ctrl+3")) {
            this.forChildProps.writeMessage = text("ctrl+3");
          }
          break;
        case "empat":
          if (check("ctrl+4")) {
            this.forChildProps.writeMessage = text("ctrl+4");
          }
          break;
        case "lima":
          if (check("ctrl+5")) {
            this.forChildProps.writeMessage = text("ctrl+5");
          }
          break;
        case "enam":
          if (check("ctrl+6")) {
            this.forChildProps.writeMessage = text("ctrl+6");
          }
          break;
        case "tujuh":
          if (check("ctrl+7")) {
            this.forChildProps.writeMessage = text("ctrl+7");
          }
          break;
        case "delapan":
          if (check("ctrl+8")) {
            this.forChildProps.writeMessage = text("ctrl+8");
          }
          break;
        case "nine":
          if (check("ctrl+9")) {
            this.forChildProps.writeMessage = text("ctrl+9");
          }
          break;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getThreads",
      "getChat",
      "getChatLog",
      "getAllVuexAgent",
      "getStartCron",
      "getVuexGeneral",
      "getDialogActive",
    ]),
    storage: function () {
      return this.mixConfigGetter();
    },
  },
  mounted() {
    this.$store.dispatch("ShortcutCanned");

    this.xhrThreads();
    this.initSyncSnoozeJSandVuex();
    this.showAlertGestureSound();
    /**
     * --------------------------------------------------------------
     * request background color buble chat
     * --------------------------------------------------------------
     */
    this.$store.dispatch("BubbleColor");

    localStorage.openpages = Date.now();
    let onLocalStorageEvent = function (e) {
      if (e.key === "openpages") {
        localStorage.page_available = Date.now();
      }
      if (e.key === "page_available") {
        window.location = `/panel/restrict?tm=${e.newValue}`;
      }
    };
    window.addEventListener("storage", onLocalStorageEvent, false);
  },
  created() {
    this.initCreated();

    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
    });
  },
};
</script>

<style>
@import "../assets/qiscus/menu.css";
@import "../assets/qiscus/main.css";
@import "../assets/qiscus/qiscus-sdk.2.10.0.css";
@import "../assets/qiscus/inbox.css";
@import "../assets/qiscus/flex.css";
@import "../assets/qiscus/override.css";
@import "../assets/qiscus/simplemde.min.css";
@import "../assets/css/rw-custom.css";
.all-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
</style>

<style scoped>
@import url("~bootstrap/dist/css/bootstrap.min.css");
</style>
